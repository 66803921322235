<template>
  <div class="wrap">
    <div class="overlay" :class="getIsOverlay ? 'overlay-block' : 'hidden'">
      <LoadingSquare></LoadingSquare>
    </div>
    <!-- header -->
    <Header></Header>
    <!--// header -->

    <div class="content-container">
      <Sidebar></Sidebar>

      <main class="content" id="main-screen">
        <router-view/>

        <!-- footer -->
        <Footer></Footer>
        <!--// footer -->
      </main>

      <!-- 알림 -->
      <div class="alarm-board" :class="{'opened' : getIsShowEvent}">
        <h2 class="h2-alarm">알림</h2>
        <button type="button" class="btn-close-alarm" @click="closeShowEvent()">
          <i class="icon-img ic-popup-close-black"></i>
          <span class="for-a11y">닫기</span>
        </button>
        <div class="component-tab-b">
          <button type="button" class="tab-item" @click="selectTab(1)" :class="tabIndex === 1 ? 'active' : ''">미확인</button>
          <!-- 20240429 이너트론 요청으로 확인 탭 없이 미확인 탭만 나오게 -->
          <!-- <button type="button" class="tab-item" @click="selectTab(2)" :class="tabIndex === 2 ? 'active' : ''">확인</button> -->
        </div>

        <div class="alarm-list" v-if="tabIndex === 1">
          <a @click="dispEventDetail(eItem.eventGuid)" class="alarm-link cursor-pointer" v-for="(eItem, idx) in getActiveEventList" :key="idx">
            <span class="ic-alarm-state2" v-if="eItem.eventType === 'detect'"><i class="icon-img ic-loading-signal-only"></i></span>
            <span class="ic-alarm-state3" v-else><i class="icon-img ic-exclamation-mark"></i></span>
            
            <p class="alarm-info">
              <span class="location">{{eItem.siteName}} > {{eItem.mapName}} > {{eItem.deviceName}}</span>
              
              <span class="subject">{{eventTypeDesc[eItem.eventType]}}</span>
            </p>
            <span class="date">
              <TimeDiff :timeStr="eItem.insertDate"></TimeDiff>
            </span>
          </a>
        </div>

        
        <div class="alarm-list" v-if="tabIndex === 2">
          <a @click="dispEventCheckDetail(eItem.eventGuid)" class="alarm-link cursor-pointer" v-for="(eItem, idx) in checkEventList" :key="idx">
            <span class="ic-alarm-state2" v-if="eItem.eventType === 'detect'"><i class="icon-img ic-loading-signal-only"></i></span>
            <span class="ic-alarm-state3" v-else><i class="icon-img ic-exclamation-mark"></i></span>
            
            <p class="alarm-info">
              <span class="location">{{eItem.siteName}} > {{eItem.mapName}} > {{eItem.deviceName}}</span>
              
              <span class="subject">{{eventTypeDesc[eItem.eventType]}}</span>
            </p>
            <span class="date">
              <TimeDiff :timeStr="eItem.insertDate"></TimeDiff>
            </span>
          </a>
        </div>

        <button type="button" class="btn-more" v-if="tabIndex === 2 && checkEventList.length < totalCount" @click="moreLoadEvent()">더보기</button>
      </div>
      <!--// 알림 -->
    </div>


    <!-- 알림 상세 다이얼로그 START -->
    <div class="module-dimmed" v-if="showEventDetailDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">알림 상세</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content detail-content">
            <div class="detail-title">알림 기본정보</div>
            <div class="detail-group">
              <div class="detail-item">
                <span class="detail-label">일시</span>
                <div class="detail-info">{{eventInfo.insertDate | moment('YYYY-MM-DD HH:mm:ss')}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">구분</span>
                <div class="detail-info">{{eventTypeDesc[eventInfo.eventType]}}</div>
              </div>

              <div class="detail-item" v-if="eventInfo.checkerID">
                <span class="detail-label">최초확인ID</span>
                <div class="detail-info">{{eventInfo.checkerID}}</div>
              </div>
              <div class="detail-item" v-if="eventInfo.checkDate">
                <span class="detail-label">최초확인 일시</span>
                <div class="detail-info">{{eventInfo.checkDate | moment('YYYY-MM-DD HH:mm:ss')}}</div>
              </div>

              
            </div>
            <div class="detail-title">단말기 정보</div>
            <div class="detail-group">
              <div class="detail-item">
                <span class="detail-label">단말ID</span>
                <div class="detail-info">{{eventInfo.deviceID}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">단말기명</span>
                <div class="detail-info txt-blue">{{eventInfo.deviceName}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">고객사/운용부서</span>
                <div class="detail-info">{{eventInfo.companyName}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">관리구역</span>
                <div class="detail-info">{{eventInfo.siteName}}</div>
              </div>
              <div class="detail-item">
                <span class="detail-label">상세위치</span>
                <div class="detail-info">
                  {{eventInfo.mapName}}
                  <!-- <button type="button" class="element-btn bg-gray size-2xs ml-10">맵으로 가기</button> -->
                </div>
              </div>
            </div>
            
            <div v-if="eventInfo.eventType === 'detect'">
              <div class="detail-title mt-10">불법 카메라 탐지</div>  
              <div class="detail-group py-2 border-b border-gray-100 border-solid" v-for="(dItem, idx) in eventInfo.detectEventInfoList" :key="idx">
                <div class="detail-item">
                  <span class="detail-label">탐지시간</span>
                  <div class="detail-info">{{dItem.insertDate}}</div>
                </div>
                <div class="detail-item">
                  <span class="detail-label">불법카메라 MAC주소</span>
                  <div class="detail-info">{{dItem.detectMacAddress}}</div>
                </div>
                <div class="detail-item">
                  <span class="detail-label">반경(m)</span>
                  <div class="detail-info">{{dItem.detectDistance}}m</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer full-type">
          <button type="button" class="element-btn bg-gray size-lg" @click="closeEventDetail()">닫기</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="dispEventConfirm(eventInfo.eventGuid, eventInfo.deviceGuid, eventInfo.checkerID)">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeEventDetail()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 알림 상세 다이얼로그 END -->


    <!-- 알림 체크 확인 다이얼로그 START -->
    <div class="module-dimmed" v-if="showEventConfirmDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="confirm-text">해당 알림을 확인하시겠습니까?<br>단말 확인화면으로 이동 합니다.</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="closeEventConfirm()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="checkEvent()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="closeEventConfirm()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 알림 체크 확인 다이얼로그 END -->


    <!-- Alert 다이얼로그 START -->
    <div class="module-dimmed" v-if="getIsAlert.status">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="title">{{getIsAlert.string}}</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-blue size-lg" @click="closeAlertDialog(getIsAlert.reload)">확인</button>
        </div>
        <button type="button" class="btn-close" @click="closeAlertDialog(getIsAlert.reload)">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- Alert 다이얼로그 END -->

  </div>
</template>

<script>
import Header from "@/components/layout/Header"
import Sidebar from "@/components/layout/Sidebar"
import Footer from "@/components/layout/Footer"

import LoadingSquare from '@/components/common/LoadingSquare'

import { mapGetters, mapActions } from 'vuex'
import { requestApi } from "@/utils/api"
import { decryptString } from "@/utils/config"

import RpcBuilder from "kurento-jsonrpc";
import { baseAddr, basePort, baseSocket } from "@/references/config"
import TimeDiff from "@/components/elements/TimeDiff"

import {eventTypeDesc} from "@/references/config"

export default {
  created () {
    if(this.getCurrentUser == null) {
      this.$router.push({name:"LoginPage"});
    }
    else {
      if(this.getCurrentUser.ur && this.getCurrentUser.ur.userRole) {
        this.chgUserRole(decryptString(this.getCurrentUser.ur.userRole).split('|@|')[2])
        this.chgCompanyGuid(this.getCurrentUser.ur.getCompanyGuid)
      }
    }

    if(sessionStorage.getItem('deviceFilter') == null) this.chgDeviceFilterStatus(JSON.stringify({ normal: true, detect: true, offline: true }))

    this.startWs()
    this.getEventAction()
  },
  components: {
    Header,
    Sidebar,
    Footer,
    TimeDiff,
    LoadingSquare
  },
  data() {
    return {
      eventTypeDesc,
      isShowAlarm: false,
      tabIndex: 1,
      showEventDetailDialog: false,
      eventInfo: {},

      showEventConfirmDialog: false,
      confirmEventGuid: '',
      confirmDeviceGuid: '',

      limit: 10,
      currentPage: 1,
      totalCount: 0,
      checkEventList: [],

      audioPlay: null,
      audioFile: null,
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser','getIsOverlay','getCompanyGuid','getEventTimestamp','getActiveEventList','getIsShowEvent','getIsAlert','getDeviceFilterStatus'])
  },
  mounted() {
    this.menuToggle();
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgCompanyGuid","chgUserRole","chgEventTimestamp","chgActiveEventList","chgIsShowEvent","chgIsAlert","chgDeviceFilterStatus","chgOnlineDevice","chgOfflineDevice"]),
    closeAlertDialog (isReload) {
      console.log(isReload)
      this.chgIsAlert({status: false, string: ''})
      if(isReload) {
        window.location.reload();
      }
    },
    menuToggle() {
      const hasMenu = document.querySelectorAll('.has-menu');
      for (let i = 0; i < hasMenu.length; i++) {
        hasMenu[i].addEventListener('click', function() {
          this.classList.toggle('active');
        })
      }
    },

    selectTab(idx) {
      this.tabIndex = idx
      if(idx === 2) {
        this.checkEventList = []
        this.totalCount = 0
        this.currentPage = 1
        this.getCheckEvent()
      }
    },
    dispEventDetail (guid) {
      this.getActiveEventInfo(guid)
      this.showEventDetailDialog = true
    },
    closeEventDetail () {
      this.showEventDetailDialog = false
      this.eventInfo = {}
    },


    dispEventCheckDetail (guid) {
      this.getCheckEventInfo(guid)
      this.showEventDetailDialog = true
    },

    getEventAction () {
      this.getActiveEvent()
    },
    async getActiveEvent () {
      let reqObj = {}
      reqObj.target = `/event/activelist`;
      reqObj.method = "get";
      reqObj.params = {
        "companyGuid": this.getCompanyGuid ? this.getCompanyGuid : '0',
        "siteGuid": this.siteSearchGuid ? this.siteSearchGuid : '0',
        "mapGuid": this.mapSearchGuid ? this.mapSearchGuid : '0',
        "deviceID": this.deviceSearchID ? this.deviceSearchID : '',
        "eventType": this.selectEventType ? this.selectEventType : '',
        "startTimestamp": 0,
        "endTimestamp": 0,
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgActiveEventList(result.msg.retData)
      }
    },

    dispEventConfirm (eventGuid, deviceGuid, checkerID) {
      if(checkerID != null) {
        this.showEventDetailDialog = false
        this.eventInfo = {}
      }
      else {
        this.confirmEventGuid = eventGuid;
        this.confirmDeviceGuid = deviceGuid;
        this.showEventConfirmDialog = true
      }
      
    },
    closeEventConfirm () {
      this.confirmEventGuid = ''
      this.confirmDeviceGuid = ''
      this.showEventConfirmDialog = false
    },

    async getActiveEventInfo (guid) {
      let reqObj = {}
      reqObj.target = `/event/activeinfo/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.eventInfo = result.msg.retData
      }
    },

    async getCheckEventInfo (guid) {
      let reqObj = {}
      reqObj.target = `/event/checkinfo/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.eventInfo = result.msg.retData
      }
    },


    closeShowEvent () {
      this.chgIsShowEvent(false)
      this.tabIndex = 1
    },

    async checkEvent () {
      if(this.confirmEventGuid!="" || this.confirmEventGuid!=null) {
        let reqObj = {}
        reqObj.target = `/event/checkevent`;
        reqObj.method = "put";
        reqObj.params = {};
        reqObj.data = {
          "eventGuid":this.confirmEventGuid
        };
        reqObj.headers = { "accept":"application/json" };

        let result = await requestApi(reqObj);
        if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
          let targetGuid = this.confirmDeviceGuid
          
          this.showEventConfirmDialog = false
          this.confirmEventGuid = ''
          this.confirmDeviceGuid = '';

          this.showEventDetailDialog = false
          this.eventInfo = {}

          this.chgIsShowEvent(false)
          this.tabIndex = 1

          this.getEventAction()

          this.$router.push({"name":"DefaultDevice","query":{guid:targetGuid}}).catch(()=>{});
        }
      }
    },


    moreLoadEvent () {
      this.currentPage++
      this.getCheckEvent()
    },
    async getCheckEvent () {
      let reqObj = {}
      reqObj.target = `/event/checklist`;
      reqObj.method = "get";
      reqObj.params = {
        "companyGuid": this.getCompanyGuid ? this.getCompanyGuid : '0',
        "siteGuid": this.siteSearchGuid ? this.siteSearchGuid : '0',
        "mapGuid": this.mapSearchGuid ? this.mapSearchGuid : '0',
        "deviceID": this.deviceSearchID ? this.deviceSearchID : '',
        "eventType": this.selectEventType ? this.selectEventType : '',
        "startTimestamp": 0,
        "endTimestamp": 0,
        "offset": this.currentPage - 1,
        "limit": this.limit
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.checkEventList.push(...result.msg.retData.content)
        this.totalCount = result.msg.retData.totalElements
      }
    },

    async checkIsEventSound (deviceGuid, eventType) {
      console.log(this.getCompanyGuid, deviceGuid)
      //af656209-26df-43d3-8177-49078447df16
      let reqObj = {}
      reqObj.target = `/event/checksound`;
      reqObj.method = "get";
      reqObj.params = {
        "companyGuid": this.getCompanyGuid,
        "deviceGuid": deviceGuid,
        "eventType": eventType
      };
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.audioFile = result.msg.retData
      }
    },

    onEventBoot (f) {
      console.log("boot", f)

      this.chgEventTimestamp(new Date().getTime())
      this.chgOnlineDevice(f.deviceGuid + "|@|" + new Date().getTime())
      this.getEventAction()
    },

    onEventOnline (f) {
      console.log("online", f)
      
      this.chgOnlineDevice(f.deviceGuid + "|@|" + new Date().getTime())
    },

    onEventDetect (f) {
      console.log("detect", f)

      this.checkIsEventSound(f.deviceGuid, "detect")
      this.chgEventTimestamp(new Date().getTime())
      this.getEventAction()
    },

    onEventOffline (f) {
      console.log("offline", f)

      let deviceGuidArray = f.diviceInfos.split(",")
      for(let i=0; i<deviceGuidArray.length; i++) {
        this.checkIsEventSound(deviceGuidArray[i], "offline")

        this.chgOfflineDevice(deviceGuidArray[i] + "|@|" + new Date().getTime())
      }
      this.chgEventTimestamp(new Date().getTime())
      this.getEventAction()
    },

    onEventClear (f) {
      console.log("clear", f)

      this.chgEventTimestamp(new Date().getTime())
      this.getEventAction()
    },

    onEventBlock (f) {
      console.log("clear", f)

      this.chgEventTimestamp(new Date().getTime())
    },

    /**  WebSocket Connect START **/
    startWs() {
      console.log("startWS")
      const config = {
        heartbeat: 5000,
        sendCloseMessage: false,
        ws: {
          uri: `${baseSocket}://${baseAddr}:${basePort}/smcomInnertron`,
          useSockJS: false,
          onconnected: this.onConnectSuccess,
          ondisconnect: this.disconnectCallback,
          onreconnecting: this.reconnectingCallback,
          onreconnected: this.reconnectedCallback
        },
        rpc: {
          requestTimeout: 300000,
          EVENT_BOOT: this.onEventBoot,
          EVENT_ONLINE: this.onEventOnline,
          EVENT_DETECT: this.onEventDetect,
          EVENT_OFFLINE: this.onEventOffline,
          EVENT_CLEAR: this.onEventClear,
          EVENT_BLOCK: this.onEventBlock
        }
      };
      this.jsonRpcClientTop = new RpcBuilder.clients.JsonRpcClient(config);
      console.log(this.jsonRpcClientTop);
    },
    onConnectSuccess () {
      console.log('Websocket connection success');
      setTimeout(() => this.register(), 1000);
    },
    closeWs () {
      this.jsonRpcClientTop.close(4102, "Connection closed by client");
    },
    sendRequest (method, params, callback) {
      if (params && params instanceof Function) {
        callback = params;
        params = {};
      }
      console.log('Sending request: {method:"' + method + '", params: ' + JSON.stringify(params) + '}');
      this.jsonRpcClientTop.send(method, params, callback);
    },
    disconnectCallback () {
      console.warn('Websocket connection lost');
    },
    reconnectingCallback () {
      console.warn('Websocket connection lost (reconnecting)');
      this.refreshCount++
      console.log("refreshCount", this.refreshCount)
    },
    reconnectedCallback () {
      console.warn('Websocket reconnected');
      setTimeout(() => this.register(), 1000);
    },
    register() {
      const regParams = {
        authtoken: "INNERTRON",
      };
      this.sendRequest('register', regParams, (error, response) => {
        return new Promise((resolve, reject) => {
          if (error) {
            reject(error);
          } else {
            console.log(response);
            resolve();
          }
        });
      });
    },
    /**  WebSocket Connect END **/
  },
  watch: {
    'audioFile': function () {
      if(this.audioFile!=null) {
        this.audioPlay = new Audio(this.audioFile)
        this.audioPlay.play()
      }
    }
  }
}
</script>

<style lang="scss" src="../assets/styles/style.scss"></style>