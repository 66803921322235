<template lang="">
  <div class="flex justify-center items-center relative ml-5 mr-2" v-click-outside="closeDropdown">
    <button type="button" class="util-item" @click="isOpen = !isOpen">
      <i class="ic-top-navi-users"></i>
      <span class="for-a11y">내정보</span>
      <span class="text-white mx-2">{{getCurrentUser.ur.userID}} ({{managerRole[getUserRole]}})</span>
    </button>
    <button v-if="isOpen" @click="isOpen = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default"></button>
    
    <div v-if="isOpen" class="absolute top-10 right-1 py-2 mt-2 rounded-lg border-gray-900 bg-white shadow-xl min-w-max px-5" style="z-index:10000">
      <p @click="moveUserAdmin()" class="text-sm text-gray-900 hover:bg-pbx-600 hover:text-gray-400 block px-4 py-2 cursor-pointer border-b border-gray-100 border-solid">회원정보</p>
      <p @click="moveEventList()" class="text-sm text-gray-900 hover:bg-pbx-600 hover:text-gray-400 block px-4 py-2 cursor-pointer border-b border-gray-100 border-solid">알림목록</p>
      <p @click="logoutAction()" class="text-sm text-gray-900 hover:bg-pbx-600 hover:text-gray-400 block px-4 py-2 cursor-pointer">로그아웃</p>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapActions, mapGetters } from "vuex";

import { managerRole } from '@/references/config'

export default {
  props: {
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters(["getUserRole","getCompanyGuid","getCurrentUser"])
  },
  data () {
    return {
      isOpen: false,
      managerRole
    }
  },
  methods: {
    ...mapActions(["userLogoutAction"]),
    closeDropdown () {
      this.isOpen = false
    },
    logoutAction () {
      this.userLogoutAction();
      this.$router.push({name:"IndexPage"})
    },
    moveUserAdmin () {
      this.$router.push({name:"UserAdmin"})
    },
    moveEventList () {
      this.$router.push({name:"EventPage"})
    }
  }
}
</script>
<style lang="">
</style>