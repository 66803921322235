<template lang="html">
  <footer class="footer">
    <p class="copyright">Copyright© SMCOM. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  
}
</script>

<style lang="">
  
</style>