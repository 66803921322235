<template lang="html">
  <div>
    {{ this.timeStr ? diffTime + '분 전' : '미확인'}}
  </div>
</template>

<script>
export default {
  props: {
    timeStr:  String,
  },
  created () {
    this.getTimeDiff();
  },
  data () {
    return {
      diffTime: '',
      timeLoop: null,
    }
  },
  methods: {
    getTimeDiff () {
      if(this.timeStr == null) this.diffTime = "미확인"
      else {
        this.timeLoop = setInterval(() => {
          const time1 = this.$moment(this.timeStr)
          const time2 = this.$moment(new Date());

          this.diffTime = Math.round(this.$moment.duration(time2.diff(time1)).asMinutes())
        }, 1000)
      }
    }
  },
  destroyed () {
    if(this.timeLoop!=null) clearInterval(this.timeLoop)
  },
  watch: {
    'timeStr': function () {
      if(this.timeLoop!=null) clearInterval(this.timeLoop)
      if(this.timeStr) this.getTimeDiff()
    }
  }
}
</script>

<style lang="">
  
</style>