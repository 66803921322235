<template lang="html">
  <header class="header">
    <h1 class="logo flex justify-start items-center">
      <img :src="companyLogo" v-if="companyLogo!=null" style="max-height:50px;" />
      <span class="mx-3">{{ this.companyInfo.companyLogo }} 불법 카메라 모니터링 시스템</span>
    </h1>
    <div class="util-menu flex justify-end items-center">
      <button type="button" class="util-item mx-8" @click="dispShowEvent()">
        <i class="ic-top-navi-notification-01"></i>
        <span class="for-a11y">알림</span>
        <i class="badge-new px-2">{{getActiveEventList.length > 999 ? '999+' : getActiveEventList.length}}</i>
      </button>
      <!-- <button type="button" class="util-item">
        <i class="ic-top-navi-users"></i>
        <span class="for-a11y">내정보</span>
      </button> -->
      <DropdownUser></DropdownUser>
    </div>
  </header>
</template>


<script>
import { mapGetters, mapActions } from "vuex"
// import { requestApi, requestGetDownload, requestPostDownload } from "@/utils/api"
import { requestApi } from "@/utils/api"
import DropdownUser from "@/components/elements/DropdownUser"

export default {
  created () {
    this.getCompanyInfo()
  },
  components: {
    DropdownUser
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getUserRole","getCompanyGuid","getIsShowEvent","getActiveEventList"]),
  },
  data () {
    return {
      alarmCount: 100,
      companyInfo: {},
      companyLogo: null
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsShowEvent"]),
    dispShowEvent () {
      this.chgIsShowEvent(true)
    },
    async getCompanyInfo () {
      let reqObj = {}
      reqObj.target = `/company/info/${this.getCurrentUser.ur.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companyInfo = result.msg.retData
        console.log(this.companyInfo.companyLogo)
        if(this.companyInfo.companyLogo != null && this.companyInfo.companyLogo != '') document.title = this.companyInfo.companyLogo
        if(this.companyInfo.fileGuid != null) this.getLogoFile(this.companyInfo.fileGuid)
      }
    },
    async getLogoFile (guid) {
      let reqObj = {}
      reqObj.target = `/files/img/${guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companyLogo = result.msg.retData
      }
    }
  }
}
</script>

<style lang="">
  
</style>